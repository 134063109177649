<template>
    <div>
        <van-nav-bar
          title="特殊情况申请"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        />
        <div class="height-10"></div>
        <van-cell-group :border="false">
            <van-cell :border="false" class="padding-t-b-5">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <div class="custom-title font-16"><div class="left-title  font-16">项目编号:</div><span>{{datas.projectitemcode}}</span></div>
              </template>
            </van-cell>
            <van-cell :border="false" class="padding-t-b-5">
              <!-- 使用 title 插槽来自定义标题 -->
              <template #title>
                <div class="custom-title font-16"><div class="left-title  font-16">项目名称:</div><span class="weight-blod">{{datas.projectitemname}}</span></div>
              </template>
            </van-cell>

            <van-cell :border="false" class="padding-t-b-5">
                <template #title>
                    <div class="custom-title font-16">特殊情况选择<span class="red">*</span></div>
                </template>
            </van-cell>

            <van-cell :border="false" class="padding-t-b-5">
                <template #title>
                    <van-radio-group v-model="type" direction="horizontal">
                        <van-radio name="1">延期调查</van-radio>
                        <van-radio name="2">不调查</van-radio>
                    </van-radio-group>
                </template>
            </van-cell>
            <van-cell :border="false" class="padding-t-b-5">
                <template #title>
                    <div class="custom-title font-16">申请理由<span class="red">*</span></div>
                </template>
            </van-cell>
            
            <van-cell :border="false" class="padding-t-b-5">
                <template #title>
                    <van-field
                      class="contacts-field-textarea"
                      v-model="reason"
                      rows="4"
                      autosize
                      type="textarea"
                      maxlength="255"
                      show-word-limit
                    />
                </template>
            </van-cell>
            <van-button type="info" class="send-btn" :disabled="disabled" block @click="doSpecial()">提交</van-button>

        </van-cell-group>
    </div>
</template>
<script>
import {project,save,special} from "@/api/user";
import {ticket, shareTitle, shareUrl, shareImg, shareDesc, commonShare} from "@/api/wxshare";
import $ from  'jquery'
export default {
    name:'ContactsSpecial',
    data() {
        return {
            params:this.$route.params,
            datas:{},
            disabled:false,
            type:0,
            reason:'',
        }
    },
    created() {
        let _this = this;
        _this.updateShare();
        _this.getProject();
    },
    methods: {
        async updateShare() {
            let _this = this;
            const res = await ticket({url:window.location.href});
            if (res.code == 1) {
                commonShare(res.data, shareTitle, window.location.href, shareImg, shareDesc)
            }
        },
        onClickLeft() {
            history.go(-1);
        },
        async getProject() {
            let _this = this;
            const res = await project({id:_this.params.id});
            if (res.code == 1) {
                _this.datas = res.data;
            }
        },
        async doSpecial() {
            let _this = this;
            _this.disabled = true;
            const res = await special({id:_this.params.id,type:_this.type,reason:_this.reason});
            if (res.code == 1) {
                this.$toast.setDefaultOptions({ duration: 1000 });
                this.$toast.success(res.msg);
                setTimeout(ress=> {
                    this.$router.push({
                        path:'/'
                    })
                }, 1000); 
            } else {
                _this.disabled = false;
            }
        }
    }
}

</script>